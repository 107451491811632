<template>
  <b-container class="vehicle-details">
    <h3 class="text-primary title text-center">{{ $t("listing.label.details") }}</h3>

    <TheListingCard />

    <b-container class="border-top mt-5">
      <div class="my-5">
        <h4>
          {{ $store.getters.listingPlanName }}:
          <b class="text-primary">${{ $store.getters.listingPlanPrice }}</b>
        </h4>
      </div>

      <div class="border p-3 my-3">
        <VCreditCard @change="creditInfoChanged" :trans="creditTranslations" />
        <div style="width: 400px;padding: 0 20px;margin: 0 auto">
          <v-btn
            @click="payListingFee()"
            :disabled="payable"
            class="px-5"
            rounded
            block
            :loading="payLoading"
            depressed
            color="primary"
          >
            <b>{{ $t("ListingProcess.Pay") }} ${{ $store.getters.listingPlanPrice }} {{ $t("ListingProcess.Now") }}</b>
          </v-btn>
        </div>
      </div>
      <div class="p-2 border border-danger text-danger text-center" v-show="errorMessage">
        {{ errorMessage }}
      </div>
    </b-container>
    <div class="d-flex justify-content-between align-items-center wizard-footer">
      <b-button pill variant="half-dark" class="px-3 cancelButton" v-b-modal.cancelModal
        ><v-icon>mdi-close</v-icon> {{ $t("global.button.cancel") }}
      </b-button>
      <div class="saveAndLeave">
        <TheSaveAndLeave />
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";

export default {
  components: {
    VCreditCard,
    TheListingCard: () => import("@/components/listing/TheListingCard"),
  },
  data() {
    return {
      page: "https://stripe.com/" + this.$store.getters.lang + "-ca/about",
      errorMessage: null,
      name: null,
      cardNumber: null,
      expiration: null,
      security: null,
      payLoading: false,
      creditTranslations: {
        name: {
          label: this.$t("Reservation.CreditCardName"),
          placeholder: this.$t("Reservation.CreditCardNamePlaceHolder"),
        },
        card: {
          label: this.$t("Reservation.CreditCard"),
          placeholder: this.$t("Reservation.CreditCardPlaceHolder"),
        },
        expiration: {
          label: this.$t("Reservation.CreditCardExpiration"),
        },
        security: {
          label: this.$t("Reservation.CreditCardSecurity"),
          placeholder: this.$t("Reservation.CreditCardSecurityPlaceHolder"),
        },
      },
    };
  },
  computed: {
    payable() {
      if (this.name && this.cardNumber && this.expiration && this.security) return false;
      else return true;
    },
  },
  methods: {
    creditInfoChanged(values) {
      for (const key in values) {
        this[key] = values[key];
      }
    },
    async payListingFee() {
      try {
        this.payLoading = true;
        await axios.post("Listing/Payment", {
          ListingId: this.$store.getters.listingStockID,
          CardNo: this.cardNumber.replace(/\s/g, ""),
          HolderName: this.name,
          ExpireYear: "20" + this.expiration.split("/")[1],
          ExpireMonth: this.expiration.split("/")[0],
          CVV: this.security,
        });
        // window.location.href = response.data;
        this.ListingDone();
      } catch (error) {
        this.errorMessage = error;
        console.log(error.data);
      }finally {
        this.payLoading = false;
      }
    },
    async ListingDone() {
      try {
        const response = await axios.post("Listing/ListingDone", {
          ListingId: this.$store.getters.listingStockID,
        });
        console.log("ListingDone", response);
        this.$router.push({ name: "ListingSuccess" });
      } catch (error) {
        console.log(error.data);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.cancelButton {
  margin-left: 210px;
}
</style>
